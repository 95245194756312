define("bocce/mixins/interactions/reveal_content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function RevealContent(el, data) {
    data = convertToJSON(el[0]);
    this.eleId = data.reveal_content_id;
    this.data = data;
    if (Ember.$("#" + this.data.reveal_content_id).length) {
      this.el = Ember.$("#" + this.data.reveal_content_id);
    } else {
      this.el = Ember.$("<div id='" + this.eleId + "'></div>");
    }
    this.el.css("text-align", "center");
  }
  function convertToJSON(el) {
    console.log("element", el);
    const jsonData = [];
    const rows = el.querySelectorAll('table tr');
    let image = rows[0].querySelector('.image-component img');
    let caption = rows[0].querySelector("p").innerText;
    let revealText = rows[1].querySelector("td > *:not(.image-component)")?.innerHTML;
    let revealImage = rows[1].querySelector("img")?.src;
    let revealImageCaption = rows[1].querySelector(".component_caption")?.innerText;
    console.log(revealText);
    jsonData.push({
      id: 1,
      tabIndex: 1,
      ariaLabel: caption,
      image: image.src,
      imageAlt: image.alt,
      titleText: caption,
      closeTabIndex: "-1",
      revealData: {
        revealText,
        revealImage,
        revealImageCaption
      }
    });
    return {
      reveal_content_id: el.id,
      initial_json: jsonData
    };
  }
  RevealContent.prototype = {
    init: function () {
      this.el.empty();
      for (let i = 0; i < this.data.initial_json.length; i++) {
        const currRevealData = this.data.initial_json[i];
        let $currCollapseEle = this.createComponent(currRevealData);
        $currCollapseEle = Ember.$($currCollapseEle);
        $currCollapseEle.find('.pointer.tababble').on('click', function () {
          Ember.$(this).next().toggleClass("show");
          Ember.$(this).parent().find(".close").toggleClass('rotate');
        });
        this.el.append($currCollapseEle);
      }
      Ember.$("#mainContainer").append(this.el);
    },
    createComponent: function (currData) {
      let self = this;
      let interactivityId = this.eleId.replace('area', '');
      let parentId = this.eleId;
      let str = "";
      str += '<div id="content_' + interactivityId + "_" + currData.id + '" class="interaction_content RevealContent">';
      str += '<div class="pointer collapsed tababble" tabindex="' + currData.tabindex + '" data-toggle="collapse" data-target="#collapse_' + interactivityId + "_" + currData.id + '" aria-expanded="false" aria-label="' + currData.ariaLabel + '" role="button">';
      str += '<div class="component image-component float-left clear-none booted">';
      str += '<img src="' + currData.image + '" title="" alt="' + currData.imageAlt + '">';
      str += '</div>';
      str += '<p class="body">' + currData.titleText + '</p>';
      str += '<button class="close" aria-label="Open" tabindex="' + currData.closeTabindex + '"><i class="fas fa-plus" aria-hidden="true"></i></button>';
      str += '</div>';
      str += '<div id="collapse_' + interactivityId + "_" + currData.id + '" class="collapse" data-parent="#' + parentId + '">';
      str += '<div class="row">';
      if (currData.revealData.revealImage) {
        if (currData.revealData.revealText) {
          str += '<div class="col-7 col-xl-8">';
          str += '<p class="body">' + currData.revealData.revealText + '</p>';
          str += '</div>';
        }
        str += `<div class="${currData.revealData.revealText ? "col-5 col-xl-4" : "col-12"}">`;
        str += '<div class="component image-component  clear-none booted">';
        str += '<img src="' + currData.revealData.revealImage + '" title="' + (currData.revealData.revealImageCaption || "") + '" alt="" tabindex="-1" class="img-fluid mx-auto d-block ">';
        if (currData.revealData.revealImageCaption) {
          str += '<div class="component_caption">';
          str += '<p class="body"> ' + currData.revealData.revealImageCaption + ' </p>';
          str += '</div>';
        }
        str += '</div>';
      } else {
        str += '<div class="col">';
        str += '<p class="body">' + currData.revealData.revealText + '</p>';
        str += '</div>';
      }
      str += '</div> </div> </div>';
      return str;
    }
  };
  var _default = _exports.default = RevealContent;
});